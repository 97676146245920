import CovidUpdate from 'components/global/covidUpdate'
import EmailSignup from 'components/global/emailSignup'
import Layout from 'components/global/layout'
import Buttons from 'components/index/buttons'
import GallerySlider from 'components/index/gallerySlider'
import IndexHeader from 'components/index/headerCropped'
import Intro from 'components/index/intro'
import Reviews from 'components/index/reviews'
import Video from 'components/index/video'
import {graphql} from 'gatsby'
import React from 'react'
import MainBackground from 'images/backgrounds/wooden-background.jpeg'
import styled from 'styled-components'

const meta = {
    title:
        'The Mousetrap Broadway',
    descirption:
        'Agatha Christie’s genre-defining murder-mystery and the world’s most successful and longest-running play comes to Broadway for a limited engagement.  Sign Up for more information.',
}

const Background = styled.div`
  position: relative;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center center;
`

const IndexPage = ({data}) => {
    const images = data.Images.nodes
    const review = data.Review.nodes
    return (
        <Layout title={meta.title} description={meta.descirption}>
            <IndexHeader/>
            <Background img={MainBackground}>
                <Intro/>
                {/* <CovidUpdate /> */}
                {/*<Buttons />*/}
                {/*<Video />*/}
                {/*<GallerySlider data={images} />*/}
                {/*<Reviews data={review} />*/}
                <EmailSignup/>
            </Background>
        </Layout>
    )
}

export default IndexPage

export const IndexQuery = graphql`
  query IndexQuery {
    Images: allSliderJson(limit: 5) {
      nodes {
        image {
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    Review: allReviewsJson {
      nodes {
        review
        reviewer
      }
    }
  }
`
