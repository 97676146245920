import MaxWidth from 'components/functional/maxWidth'
import {Link} from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import {media} from 'utils/Media'

const IntroStyles = styled.section`
  width: 100%;
  text-align: center;
  padding: 2rem 0;

  @media ${media.sm} {
    padding: 4rem 0;
    width: 90%;
    margin: var(--auto);
  }
  @media ${media.md} {
    padding-bottom: 2rem;
  }
  @media ${media.lg} {
    padding-bottom: 4rem;
    width: 75%;
  }
  @media ${media.xl} {
    width: 100%;
  }

  h2 {
    margin: 1rem 0 2rem 0;
    line-height: 2.85rem;

    @media ${media.lg} {
      font-size: 2.35rem;
      margin: 0 0 2rem 0;
    }
  }

  p {
    margin: 0 auto 1rem;
    @media ${media.xl} {
      font-size: 1.25rem;
      width: 80%;
      margin: 0 auto 1rem;
    }

    a {
      display: block;
      color: var(--main);

      &:hover {
        color: var(--white);
      }
    }
  }
`
const Intro = () => {
    const i = true
    return (
        <MaxWidth>
            <IntroStyles>
                <h2>Coming to Broadway</h2>
                <p>Agatha Christie’s THE MOUSETRAP is the world’s most successful and longest-running play. Now, the genre-defining murder mystery from the best-selling novelist of all time comes to Broadway for the very first time in a limited engagement.</p>

                <p>New York audiences will be able to see and hear some of the original sights and sounds from the production as it has appeared in London since 1952. The set will be a loving recreation of Anthony Holland’s design, and for a truly authentic touch, the only surviving piece of the original set — the mantelpiece clock — will be loaned from the London production for the Broadway run. The unique backstage wind machine, imprinted with the original producer’s name and still used today, will also be shipped across the Atlantic.</p>

                <p>While many elements of the show will be indistinguishable from the London production, the unique ensemble of actors assembled for Broadway will bring this classic whodunnit to life in a new way, as every cast has done in London for a staggering 70 years.</p>

                <p>DON’T JUST SEE IT. SOLVE IT.</p>
            </IntroStyles>
        </MaxWidth>
    )
}

export default Intro
