import MaxWidth from 'components/functional/maxWidth'
import React from 'react'
import styled from 'styled-components'
import MainBackground from 'images/backgrounds/wooden-background.jpeg'
import MobileButtonBkg from 'images/backgrounds/mobile-button-border.png'
import DesktopButtonBkg from 'images/backgrounds/desktop-button-border.png'
import { StaticImage } from 'gatsby-plugin-image'
import { media } from 'utils/Media'
import LongBox from 'svgs/long-box.svg'
import { Link } from 'gatsby'

const ButtonStyles = styled.section`
  width: 100%;
  padding: 4rem 0 1rem 0;
  position: relative;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  z-index: 1;
  @media ${media.md} {
    padding: 2rem 0;
  }
  @media ${media.xl} {
    padding: 7rem 0;
  }
  &:before {
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    height: 0rem;
    @media ${media.md} {
      height: 5rem;
    }

    /* backdrop-filter: blur(0.25px); */
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    content: '';
  }
  &:after {
    position: absolute;
    bottom: 0rem;
    left: 0;
    width: 100%;
    height: 2rem;

    /* backdrop-filter: blur(0.25px); */
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
    content: '';
  }

  > div {
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-columns: 1fr;
  }
`

const Buttons = props => {
  const i = true
  return (
    <ButtonStyles img={MainBackground}>
      <div>
        <SingleButton code="z5O9V2IW" text="EVERY SHOW, EVERY SEAT AVAILABLE">
          <strong>BOOK ANY SEATS</strong>
        </SingleButton>
          <SingleButton  text="FOR UP TO 4 PEOPLE- CHAMPAGNE IN PRIVATE ROOM" directLink="/royal-box-booking/" >
            <strong>THE ROYAL BOX</strong>
          </SingleButton>
        <SingleButton
          code="IOh32PzV"
          text="25TH NOV, MIDDAY MATINEE. ALL TICKETS £19.52"
        >
          <strong>70TH ANNIVERSARY</strong>
        </SingleButton>
      </div>
      {/* <section className="small-print">
        <p>
          *Book 12 weeks in advance and save £25 per ticket on selected seats on
          all Tuesday to Thursday performances (including peak weeks) Valid on
          Premiums (Face Value £75 to £99) and Band A seats (£59.50 and
          £62.50/£65). Subject to availability.
        </p>
      </section> */}
    </ButtonStyles>
  )
}

export default Buttons

const SingleButtonStyles = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 2.75rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 385px;
  margin: -2rem auto 0 auto;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      img {
        object-fit: fill !important;
      }
    }
  }
  .longbox {
    display: none;
    @media ${media.md} {
      display: block;
    }
  }
  strong {
    color: var(--mainYellow);
    font-size: 1.75rem;
    font-weight: 500;
    padding-bottom: 1rem;
    display: block;
    margin-bottom: -0.5rem;
  }
  p {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: var(--white) !important;
  }
  @media ${media.s} {
    margin-top: -2rem;
    padding: 3.25rem 2.25rem;
    strong {
      font-size: 2rem;
    }
  }
  @media ${media.md} {
    max-width: 600px;
    margin: -1.5rem auto;
    .overlay {
      .gatsby-image-wrapper {
        display: none;
      }
      svg {
        width: 100%;
        margin-top: -1rem;
      }
    }
    .br {
      display: none;
    }
    strong {
      margin: 0;
      padding: 0;
    }
    p {
      margin: 0;
    }
  }
  cursor: pointer;
  transition: var(--transition);
  &:hover {
    /* transform: scale(1.05); */
    transform: scale(1.045, 1.025);
    p {
      color: var(--white);
      transition: none;
    }
  }
`
const SingleButton = ({ url, children, text, desktop, code, directLink }) => (
  <SingleButtonStyles desktop={desktop}>
    {
      directLink ? (
        <Link to={directLink}>
      <div className="overlay">
        <StaticImage
          src="../../images/backgrounds/mobile-button-border.png"
          alt="The Mousetrap"
        />
        <LongBox className="longbox" />
      </div>
      {children}
      <p>{text}</p>
    </Link>
      ) : (
        <Link to={`/book-tickets/?flow=${code}`}>
      <div className="overlay">
        <StaticImage
          src="../../images/backgrounds/mobile-button-border.png"
          alt="The Mousetrap"
        />
        <LongBox className="longbox" />
      </div>
      {children}
      <p>{text}</p>
    </Link>
      )
    }
  </SingleButtonStyles>
)
