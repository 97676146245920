import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import MaxWidth from 'components/functional/maxWidth'
import { media } from 'utils/Media'

import ErrorIcon from 'svgs/warning.svg'
import { navigate } from 'gatsby'

const EmailSignupStyles = styled.section`
  width: 100%;
  padding: 2rem 0;
  position: relative;
  z-index: 2;
  .title {
    text-align: center;
    margin-bottom: 1rem;
    h4 {
      font-size: 2rem;
      text-transform: uppercase;
      @media ${media.md} {
        br {
          display: none;
        }
      }
    }
    p {
      margin-top: 0.75rem;
    }
  }
  form {
    .wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      section {
        display: flex;
        flex-direction: column;
        label {
          display: block;
          margin-bottom: 0.25rem;
          font-size: 1.2rem;
        }
        input {
          background-color: var(--white);
          border-radius: 7.05px;
          border: none !important;
          padding: 0.9rem 0.85rem;
          font-size: 1.1rem;
          &::placeholder {
            color: #e5e5e5;
          }
          &:focus {
            outline: var(--main);
          }
        }
        .error {
          border: solid red 1px !important;
          &::placeholder {
            color: red;
          }
          &:focus {
            outline: red;
          }
        }
      }
      .button {
        text-align: center;
        margin: 1rem auto 0 auto;
        @media ${media.md} {
          margin: 0;
        }
        @media ${media.lg} {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;

          font-size: 1.1rem;
        }
      }
      @media ${media.md} {
        grid-template-columns: repeat(3, 1fr);
      }
      @media ${media.lg} {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    aside {
      margin: 1rem 0;
      input {
        float: left;
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
      .error-input {
        background-color: red !important;
        border: solid red 1px !important;
      }
      .error {
        color: red !important;
      }
    }
  }
  @media ${media.md} {
    padding: 4rem 0;
  }
`
const ErrorMessagesStyles = styled.aside`
  display: flex;
  flex-direction: column;
  @media ${media.md} {
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .errors {
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    margin-bottom: 0.5rem;
    background-color: var(--white);
    padding: 1rem;
    border-radius: 7.05px;
    display: flex;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
      min-width: 18px;
      fill: var(--main);
    }
    p {
      margin-bottom: 0;
      padding-left: 10px;
      color: var(--black);
    }
  }
`

const EmailSignup = () => {
  const [success, setSuccess] = useState(false)
  const [error, hasErrors] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
  })
  function onSubmit(data, e) {
    e.preventDefault()
    addToMailchimp(data.email, {
      FIRSTNAME: data.firstname,
      SECONDNAME: data.lastname,
    }).then(message => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      console.log(message.msg)
      if (message.result === 'error') {
        hasErrors(true)
      } else {
        setSuccess(true)
        navigate('/thank-you', {
          state: {
            success,
            name: data.firstname,
          },
        })
      }
    })
  }

  return (
    <MaxWidth $main id="sign-up">
      <EmailSignupStyles>
        <div className="title">
          <h4>
            Get on the <br className="desktop" /> Official list
          </h4>
          <p>
            Sign up to our email list for the latest news, announcements, ticket
            offers and updates. You can unsubscribe at any time.
          </p>
        </div>
        <form action="" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="wrapper">
            <section>
              <label htmlFor="First Name">First Name</label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="First Name"
                className={errors.firstname ? 'error' : ''}
                {...register('firstname', {
                  required: 'First name required',
                })}
              />
            </section>
            <section>
              <label htmlFor="Last Name">Last Name</label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Last Name"
                className={errors.lastname ? 'error' : ''}
                {...register('lastname', {
                  required: 'Last name required',
                })}
              />
            </section>
            <section>
              <label htmlFor="Email Address">Email Address</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email Address"
                className={errors.email ? 'error' : ''}
                {...register('email', {
                  required: 'A email address is required',
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: 'Invalid email address',
                  },
                })}
              />
            </section>
            <section className="button">
              <button type="submit" className="btn btn-alt">
                <span>SUBSCRIBE</span>
              </button>
            </section>
          </div>
          <aside>
            <input
              type="checkbox"
              name="check"
              id="check"
              {...register('check', {
                required:
                  'Please consent to Mousetrap Productions Limited using your contact details',
              })}
            />
            <p>
              By signing up to our newsletter you consent to Mousetrap Productions Limited using your contact details to keep you informed by email about its or other similar related events’ products, services and content. You may withdraw your consent at any time.
            </p>
          </aside>
        </form>
        {Object.keys(errors).length === 0 ? (
          ''
        ) : (
          <ErrorMessagesStyles>
            {errors.firstname && (
              <ErrorMessage message={errors.firstname.message} />
            )}
            {errors.lastname && (
              <ErrorMessage message={errors.lastname.message} />
            )}
            {errors.email && <ErrorMessage message={errors.email.message} />}
            {errors.check && <ErrorMessage message={errors.check.message} />}
          </ErrorMessagesStyles>
        )}
        {error ? (
          <ErrorMessagesStyles>
            <ErrorMessage message="This email is already in use" />
          </ErrorMessagesStyles>
        ) : (
          ''
        )}
      </EmailSignupStyles>
    </MaxWidth>
  )
}

export default EmailSignup

const ErrorMessage = ({ message }) => (
  <div className="errors">
    <ErrorIcon />
    <p>{message}</p>
  </div>
)
