import Social from 'components/global/social'
import {graphql, StaticQuery, useStaticQuery} from 'gatsby'
import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {media} from 'utils/Media'
import ScrollIcon from 'svgs/scroll.svg'

const IndexHeaderStyles = styled.header`
  width: 100%;
  height: 100%;
  position: relative;
`
const IndexHeader = props => {
    console.log(props)
    return (
        <IndexHeaderStyles>
            <h1 className="sr-only">The Mousetrap</h1>
            <GatsbyImage image={getImage(props.data.desktop)}
                         alt="The Mousetrap Broadway"
                         title=""
                         className="img-fluid"
            />
        </IndexHeaderStyles>
    )
}

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                desktop: file(relativePath: { eq: "221121_MOUSETRAP_City1_1920x1080_4.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
		`}
        render={data => (
            <IndexHeader props={props} data={data}/>
        )}
    />
)
export default Query

