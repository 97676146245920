import MaxWidth from 'components/functional/maxWidth'
import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

const CovidUpdateStyles = styled.div`
  width: 100%;
  margin: 0 0 4rem 0;
  section {
    border: var(--main) 2px solid;
    padding: 0.5rem;
    max-width: 500px;
    margin: 0 auto;
    transition: var(--transition);
    &:hover {
      background-color: var(--main);
      p {
        color: var(--black);
      }
    }
    p {
      transition: var(--transition);
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      font-weight: 700 !important;
    }
  }
  ${props =>
    props.$tickets &&
    css`
      margin: 2rem 0;
    `}
`
const CovidUpdate = props => {
  const i = true
  return (
    <MaxWidth $black>
      <CovidUpdateStyles {...props}>
        <section>
          <Link to="/covid-19-update/">
            <p>covid-19 precautions for your visit</p>
          </Link>
        </section>
      </CovidUpdateStyles>
    </MaxWidth>
  )
}

export default CovidUpdate
