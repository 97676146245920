import GetImage from 'components/functional/getImage'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled, { css } from 'styled-components'
import ArrowBorder from 'svgs/circle.svg'
import Arrow from 'svgs/arrow.svg'
import { Link } from 'gatsby'
import { media } from 'utils/Media'

const GallerySliderStyles = styled.section`
  ${props =>
    props.$noReadMore &&
    css`
      .button {
        display: none;
      }
    `}
  color: white;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 2rem 0;
  max-width: 2000px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  overflow: hidden;
  .slick-track {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .slick-slide {
    padding: 0;
    transition: all 0.3s ease;
    height: auto;
    &.slick-current,
    &.slick-center {
      opacity: 1 !important;
    }
    opacity: 0.2;
  }

  .photo-wrapper {
    position: relative;

    cursor: pointer;
  }

  .slick-dots {
    bottom: -25px;
    z-index: 9;

    /* @media ${media.md} {
      bottom: -35px;
    }

    @media ${media.lg} {
      bottom: -20rem;
    } */
  }

  .slick-dots li,
  .slick-dots li button {
    width: 10px;
    height: 10px;

    @media ${media.md} {
      width: 12px;
      height: 12px;
    }

    @media ${media.lg} {
      width: 20px;
      height: 20px;
    }
  }

  .slick-dots li button:before {
    color: #63dbe7;
    opacity: 1;
    font-size: 10px;

    @media ${media.md} {
      font-size: 12px;
    }

    @media ${media.lg} {
      font-size: 20px;
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .slick-slide {
    padding: 0;
    transition: all 0.3s ease;
    height: auto;
    &.slick-current,
    &.slick-center {
      opacity: 1 !important;
    }
    opacity: 0.75;
  }

  .photo-wrapper {
    position: relative;

    cursor: pointer;
  }

  .slick-slider {
    .slick-dots {
      position: relative;
      z-index: -2;
      .slick-active {
        button {
          background-color: var(--black);
          border: solid 1px var(--white);
        }
      }
      li {
        width: 8px;
        height: 8px;
        button {
          background-color: white;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          &::before {
            color: transparent;
          }
        }
      }
    }
  }
  .button {
    text-align: center;
    margin: 1rem 0;
    @media ${media.lg} {
      padding-top: 3rem;
    }
  }
`
const settings = {
  centerMode: true,
  centerPadding: '25%',
  slidesToShow: 1,
  dots: true,
  arrows: true,
  swipe: true,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        centerPadding: '100px',
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: '1px',
        centerMode: true,
      },
    },
  ],
}

const GallerySlider = props => {
  const { data } = props
  const sliderRef = useRef()
  const next = () => {
    sliderRef.current.slickNext()
  }
  const prev = () => {
    sliderRef.current.slickPrev()
  }
  return (
    <GallerySliderStyles {...props}>
      <Slider {...settings} ref={sliderRef}>
        {data.map((i, x) => (
          <SinglePhoto key={x} data={i} />
        ))}
      </Slider>
      <ButtonToggles next={next} prev={prev} />
      <div className="button">
        <Link to="/gallery/">
          <button type="button" className="btn">
            <span>View More</span>
          </button>
        </Link>
      </div>
    </GallerySliderStyles>
  )
}

export default GallerySlider

const SinglePhotoStyles = styled.div`
  position: relative;

  cursor: pointer;
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
    @media ${media.lg} {
      width: 85%;
      margin: var(--auto);
    }
  }
`

const SinglePhoto = ({ data }) => (
  <SinglePhotoStyles>
    <GetImage data={data.image.src} alt={data.image.alt} />
  </SinglePhotoStyles>
)

const ButtonTogglesStyles = styled.div`
  width: 100%;
  margin-top: -1rem;
  .button-toggle {
    width: 90%;
    margin: var(--auto);
    display: flex;
    justify-content: space-between;
    button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      padding: 1rem;
      .border {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        border: 0px solid transparent !important;
      }
      .icon {
        width: 30px;
        height: 30px;
        margin-top: -2px;
      }
      &:nth-child(2) {
        transform: rotate(180deg);
      }
    }
  }
  @media ${media.lg} {
    position: absolute;
    top: calc(0% + 36%);
    left: 0;
    .button-toggle {
      width: 55%;
      max-width: 1100px;
    }
  }
  @media ${media.xl} {
    top: calc(0% + 35%);
  }
`

const ButtonToggles = ({ next, prev }) => (
  <ButtonTogglesStyles>
    <div className="button-toggle">
      <button type="button" onClick={() => prev()}>
        <Arrow className="icon" />
      </button>
      <button type="button" onClick={() => next()}>
        <Arrow className="icon" />
      </button>
    </div>
  </ButtonTogglesStyles>
)
